<template>
<div class="container">
  <div class="top" id="bloc-0">
    <voice-header></voice-header>
  </div>
  <div class="settings-content">
    <h1>{{ $t('setting.setting') }}</h1>
    <p class="subtitle">{{ $t('setting.setting_title') }}</p>

    <div v-if="isLoggedIn">
      <div class="card user-card">
        <h2>{{ $t('setting.basic_info') }}</h2>
        <div class="user-info">
          <div class="user-details">
            <p class="user-detail"><span class="detail-label">{{ $t('setting.user_name') }}:</span> <span class="detail-content">{{ userName }}</span></p>
            <p class="user-detail"><span class="detail-label">{{ $t('setting.user_email') }}:</span> <span class="detail-content">{{ email }}</span></p>
          </div>
        </div>
        <div class="card account-card">
          <h2>{{ $t('setting.user_account') }}</h2>
          <p class="user-detail"><span class="detail-label">{{ $t('setting.user_subscript') }}:</span> <span class="detail-content">{{ subType }} - {{ subPeriod }}</span></p>
          <a :href="stripePortalLink" class="btn">{{ $t('setting.manage_subscript') }}</a>
        </div>
        <div class="card" v-if="showCharUsedPercent">
          <h2>{{ $t('setting.user_usage') }}: {{ usedChars }} / {{ totalChars }}</h2>
          <div class="usage-bar">
            <div class="usage-progress" :style="{ width: usagePercentage + '%' }"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="card login-card">
      <p>{{ $t('setting.please_login') }}</p>
    </div>
  </div>
</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from 'axios'
import { setCookie, delCookie, getCookie } from '../utils/cookies';
import VoiceHeader from '@/components/VoiceHeader.vue'
import { reportError } from '../utils/errorReporter'

export default {
  name: 'settings',
  components: {
    VoiceHeader,
  },
  head() {
    return {
      'title': this.$i18n.t('setting.setting_title'),
      'keywords': this.$i18n.t('txt2voice.keywords'),
      'description': this.$i18n.t('txt2voice.description')
    }
  },
  data() {
    return {
      email: '',
      userName: '',
      userPicture: '',
      user_subscript: 0,  // 有4个取值, 0 未订阅, 1 已订阅, 2 虽订阅,字符已用完
      customEmail: '',
      subscript_type: '',
      subType: 'Free',
      subPeriod: 'Free',
      usedChars: 0,
      totalChars: 10000,
      showCharUsedPercent: false,
      host: 'https://tiktokvoice.net',
      stripePortalLink: 'https://billing.stripe.com/p/login/14k8xPeixaRC9H25kk',
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'currentUser', 'authToken']),
    usagePercentage() {
      if (this.totalChars === 0) {
        return 0
      }
      return (this.usedChars / this.totalChars) * 100
    }
  },
  watch: {
    isLoggedIn(newValue) {
      if (newValue && this.currentUser) {
        this.getUserInfo(this.currentUser.email);
      }
    },
    subType(newValue) {
      if (newValue === 'Free') {
        this.showCharUsedPercent = false
      } else {
        this.showCharUsedPercent = true
      }
    }
  },
  methods: {
    async getUserInfo(email) {
      if (!email) {
        reportError("email is empty, email:" + email, 'settings.vue getUserInfo', '', this.email)
        return false
      }
      try {
        const uri = `${this.host}/lapi/user/profile`
        const { data } = await axios.get(uri, {
          params: { email },
          headers: { 'Content-Type': 'application/json; charset=utf-8' }
        })

        if (data.ret === 0 && data.user_info) {
          this.email = data.user_info.email
          this.userName = data.user_info.full_name
          this.userPicture = data.user_info.imgage_url
          this.user_subscript = data.user_info.user_subscript
          this.usedChars = data.user_info.used_char_count
          this.totalChars = data.user_info.total_usable
          this.customEmail = data.user_info.customEmail
          var userEmail = this.email
          if (this.customEmail && this.customEmail.trim() !== '') {
            userEmail = this.customEmail
          }
          this.stripePortalLink =  this.stripePortalLink + "?prefilled_email=" + encodeURIComponent(userEmail)
          this.subscript_type = data.user_info.subscript_type
          switch (this.subscript_type) {
            case 'starter_month':
              this.subType = "Starter";
              this.subPeriod = "Month";
              break;
            case 'starter_onepay_month':
              this.subType = "Starter";
              this.subPeriod = "One Month";
              break;
            case 'starter_year':
              this.subType = "Starter";
              this.subPeriod = "Year";
              break;
            case 'starter_onepay_year':
              this.subType = "Starter";
              this.subPeriod = "One Year";
              break;
            case 'pro_month':
              this.subType = "Pro";
              this.subPeriod = "Month";
              break;
            case 'pro_onepay_month':
              this.subType = "Pro";
              this.subPeriod = "One Month";
              break;
            case 'pro_year':
              this.subType = "Pro";
              this.subPeriod = "Year";
              break;
            case 'pro_onepay_year':
              this.subType = "Pro";
              this.subPeriod = "One Year";
              break;
            default:
              this.subType = "Free";
              this.subPeriod = "Free";
              break;
          }
        } else {
          reportError(data.msg, 'settings.vue getUserInfo', '', this.email)
        }
      } catch (error) {
        reportError(error, 'settings.vue getUserInfo', '', this.email)
      }
    },
    redirectToLogin() {
      this.$router.push('/account/login')
    },
  },
  mounted() {
    // 添加生命周期钩子确保 Google Sign In 正确初始化
    if (!this.isLoggedIn && this.currentUser) {
      this.getUserInfo(this.currentUser.email)
    }
  }
}
</script>

<style scoped>
@import '/ssr/css/second_style.css';
.subtitle {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}
.card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  padding: 30px;
  margin-bottom: 20px;
}
.usage-bar {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 20px; /* 设置进度条的高度 */
  margin-top: 10px; /* 设置进度条与上方内容的间距 */
}
.usage-progress {
  background-color: #76c7c0; /* 进度条的颜色 */
  height: 100%; /* 使进度条填满父容器 */
  transition: width 0.3s ease; /* 添加过渡效果 */
}
.user-info {
  display: flex;
  align-items: center;
  gap: 20px;
}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e0e0e0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.user-details {
  flex: 1;
}

.user-detail {
  margin: 8px 0;
}

.detail-label {
  font-weight: 500;
  color: #666;
}

.detail-content {
  margin-left: 8px;
  color: #333;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #76c7c0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  margin-top: 10px;
}

.btn:hover {
  background-color: #5fb5ae;
}

.login-card {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
}

.login-card p {
  margin-bottom: 20px;
  font-size: 16px;
}
</style>

